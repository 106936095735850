import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"

export default function Template({
  data = {}
}) {
  const { markdownRemark = {} } = data;
  const { frontmatter = {}, html } = markdownRemark;
  const { type } = frontmatter;
  return (
    <Layout>
      <article className={frontmatter.featuredImage !== null ? 'mainArticle withImage' : 'mainArticle'}>
        <header>
          <h1>{frontmatter.title}</h1>
          {frontmatter.date && <h2>{frontmatter.date}</h2>}
        </header>
      
        {frontmatter.featuredImage !== null &&
          <div className="featuredImage"><Img fluid={frontmatter.featuredImage.childImageSharp.fluid} /></div>}

        {html ? <div className="articleBody" dangerouslySetInnerHTML={{ __html: html }} /> : <div className="articleBody" />}
        
        {frontmatter.featuredImages !== null && (
        <div className="imageList">
          {frontmatter.featuredImages.map((featuredImage)=> {
            return <div className="articleImage"><Img fluid={featuredImage.childImageSharp.fluid} /></div>
          })}
        </div>
        )}
        {type === 'mini-project-update' && <Link to={data.miniProject.frontmatter.path}>See more {data.miniProject?.frontmatter?.title} mini project posts</Link>}
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query($path: String!, $miniProject: String) {
    markdownRemark(frontmatter: {path: {eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        type
        miniProject
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        featuredImages {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    miniProject: markdownRemark(frontmatter: {miniProject: {eq: $miniProject }, type: {eq: "mini-project" } }) {
      html
      frontmatter {
        path
        title
        type
        miniProject
      }
    }
  }
`;


